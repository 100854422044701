var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{directives:[{name:"sortable-table",rawName:"v-sortable-table",value:({onEnd:_vm.sortTheHeadersAndUpdateTheKey}),expression:"{onEnd:sortTheHeadersAndUpdateTheKey}"}],key:_vm.anIncrementoNumber,class:_vm.tableClass,style:(_vm.stylesTable),attrs:{"headers":_vm.headerList,"items":_vm.valueFiltered,"search":_vm.search,"height":_vm.TableHeigth,"no-results-text":"Nenhum resultado para o texto pesquisado","single-expand":_vm.singleExpand,"show-expand":_vm.showExpand,"expanded":_vm.expanded,"item-key":"id","calculate-widths":"","fixed-header":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"group-by":_vm.groupHeader,"footer-props":{
    showFirstLastPage: false,
    showCurrentPage: true,
    showCurrentPageSize: true,
    prevIcon: 'mdi-chevron-left',
    nextIcon: 'mdi-chevron-right',
    'items-per-page-options': _vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg ? [20, 40, 60, 120, -1] : _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md ? [7, 14, 38, 68, 100, -1] : [1]
  }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.showSearch || _vm.$slots.datas || _vm.exportXlsx || _vm.$slots.btnImporte || _vm.$slots.btnCadastro)?_c('v-row',{staticClass:"pt-2 px-5",staticStyle:{"padding":"18px"}},[(_vm.showSearch)?_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":"12","sm":"12","md":"7","lg":"4","xl":"6"}},[_c('v-text-field',{staticClass:"ml-1 mt-1",attrs:{"height":"43","append-icon":"mdi-magnify","color":"primary","label":"Pesquisar","outlined":"","dense":"","elevation":"0","hide-details":"","clearable":"","clear-icon":"mdi-close"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.$slots.datas)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"5","lg":"auto","xl":"auto"}},[_c('v-row',[_vm._t("datas")],2)],1):_vm._e(),_c('v-spacer'),(_vm.exportXlsx)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"auto","lg":"auto","xl":"auto"}},[(_vm.exportXlsx)?_c('v-btn',{attrs:{"block":"","color":"primary","depressed":""},on:{"click":_vm.exportExcel}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-file-excel ")]),_vm._v(" "+_vm._s('Exportar')+" ")],1):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"auto","md":"auto","lg":"auto","xl":"auto"}},[_c('v-row',[(_vm.$slots.btnCadastro)?_vm._t("btnCadastro"):_vm._e()],2)],1)],1):_vm._e()]},proxy:true},_vm._l((_vm.headerName),function(coluna,indexHeader){return {key:("header." + indexHeader),fn:function(ref){
  var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.headerName[indexHeader])?_c('v-btn',{key:("group-" + indexHeader),attrs:{"icon":"","x-small":"","title":"Agrupar os registros"},on:{"click":function($event){return _vm.setGroupHeader(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-lightbulb-group ")])],1):_vm._e(),(_vm.filters[header.value])?_c('v-menu',{key:("filter-" + indexHeader),staticStyle:{"border-radius":"20px"},attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":"","title":"Filtrar os registros"}},'v-btn',attrs,false),on),[(_vm.filters[header.value].length)?_c('v-badge',{attrs:{"bottom":"","color":"red","dot":"","offset-x":"8","offset-y":"8","overlap":""}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-filter-variant-plus ")])],1):_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"350px"}},[_c('v-list',[_c('v-list-item',[_c('v-autocomplete',{attrs:{"multiple":"","dense":"","auto-select-first":"","clearable":"","chips":"","small-chips":"","deletable-chips":"","color":"primary","items":_vm.optionsFilter(header.value),"append-icon":"mdi-filter","label":("Informe o filtro por: " + (header.text) + ":"),"hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [(index < 4)?_c('v-chip',{staticClass:"caption",attrs:{"small":""}},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])]):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.filters[header.value].length - 4)+" outros) ")]):_vm._e()]}}],null,true),model:{value:(_vm.filters[header.value]),callback:function ($$v) {_vm.$set(_vm.filters, header.value, $$v)},expression:"filters[header.value]"}})],1)],1)],1)]):_vm._e()]}}}),{key:"group.header",fn:function(ref){
  var group = ref.group;
  var groupBy = ref.groupBy;
  var items = ref.items;
  var isOpen = ref.isOpen;
  var toggle = ref.toggle;
  var remove = ref.remove;
return [_c('td',{attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"x-small":"","icon":"","title":(isOpen ? 'Recolhe os itens grupo' : 'Expande os itens do grupo' )},on:{"click":toggle}},[_c('v-icon',{attrs:{"small":"","color":(isOpen ? 'red' : 'green' )}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")])],1),_c('span',{staticStyle:{"font-size":"13px","font-weight":"bold","text-align":"center","color":"#003677"}},[_vm._v(" "+_vm._s(_vm.groupNameHeader(groupBy))+": ")]),_c('span',{staticStyle:{"font-size":"13px","text-align":"center","display":"inline-block","background-color":"#e0e0e0","border-radius":"10px"}},[_vm._v(_vm._s(group)+" ("+_vm._s(items.length)+")"),_c('br')]),_c('v-btn',{attrs:{"icon":"","x-small":"","title":"Desfaz o agrupamento dos registros do grid."},on:{"click":function () { _vm.setGroupHeader(''), remove }}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-close ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
  var item = ref.item;
return [_vm._t("expanded-item",null,{"item":item,"headers":_vm.headerList})]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex !important","flex-direction":"row"}},_vm._l((_vm.actions),function(action,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":action.color},on:{"click":function($event){return action.click(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(action.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.title))])])}),1)]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStop)+" de "+_vm._s(items.itemsLength)+" ")]}},_vm._l((_vm.slots),function(slot){return {key:slot,fn:function(ref){
  var item = ref.item;
return [_vm._t(slot,null,{"item":item})]}}}),{key:"no-data",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.noDataMsg)+" ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }